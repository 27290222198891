@use '../../../modules.scss' as *;

.root {
  display: none;
}

@include media-breakpoint-down(sm) {
  .root {
    display: flex;

    * + & {
      margin-top: 20px;
    }
  }

  .button {
    width: 50%;

    & + & {
      margin-left: 10px;
    }
  }

  .search {
    margin-top: 10px;
    width: 100%;

    & + & {
      margin-left: 10px;
    }
  }
}
